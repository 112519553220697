import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

import menu from '../../content/menu';
import footerMenu from '../../content/footer-menu';

class NotFoundPage extends React.Component {
  render() {
    return (
      <Layout 
      location={this.props.location} 
      title="Seite nicht gefunden" 
      menu={menu}
      subMenu={null}
      footerMenu={footerMenu}>
        <SEO title="404: Seite nicht gefunden" />
        <p>Diese Seite existiert nicht</p>
      </Layout>
    )
  }
}

export default NotFoundPage
