module.exports = [
    {
        name:'Über mich',
        link:'/ueber-mich',
    },
    {
        name:'Yoga',
        link:'/yoga',
        children: [
            {
                name: 'Kurse & Preise',
                link: '/yoga/kurse-und-preise',
            },
            {
                name: 'Yoga nach Sivananda',
                link: '/yoga/yoga-nach-sivananda',
            },
            {
                name: 'Yoga auf dem Stuhl',
                link: '/yoga/yoga-auf-dem-stuhl',
            },
            {
                name: 'KlangYoga',
                link: '/yoga/klang-yoga',
            },
            {
                name: 'Einzelyogastunde',
                link: '/yoga/einzelyogastunde',
            },
            {
                name: 'Yoga von zuhause aus',
                link: '/yoga/yoga-zuhause',
            },
            {
                name: 'Chakren-Arbeit',
                link: '/yoga/chakren-arbeit',
            },
            {
                name: 'Reisen',
                link: '/yoga/reisen',
            },
        ]
    },
    {
        name:'Heil-Klang-Raum',
        link:'/heil-klang-raum',
        children: [
            {
                name: 'Die Klänge und ich',
                link: '/heil-klang-raum/die-klänge-und-ich',
            },
            {
                name: 'Möglichkeiten der Klangmassage',
                link: '/heil-klang-raum/möglichkeiten-der-klangmassage',
            },
            {
                name: 'Preise',
                link: '/heil-klang-raum/preise',
            },
        ]
    },
    {
        name:'Veranstaltungen',
        link:'/veranstaltungen',
    },
    {
        name:'Angebote für ältere Menschen',
        link:'/angebote-für-ältere-Menschen',
    },  
    {
        name:'Luminous Hearts',
        link:'/luminous-hearts',
        children: [
            {
                name: 'Mantra Circle',
                link: '/luminous-hearts/mantra-circle',
            },
            {
                name: 'Wohnzimmer Singen',
                link: '/luminous-hearts/wohnzimmer-singen',
            },
            {
                name: 'Mantra-Yoga',
                link: '/luminous-hearts/mantra-yoga',
            },
            {
                name: 'Klangreisen',
                link: '/luminous-hearts/klangreisen',
            },
        ]
    },
];