module.exports = [
    {
        name:'Kontakt',
        link:'/kontakt/'
    },
    {
        name:'Impressum',
        link:'/impressum'
    },
    {
        name:'Datenschutz',
        link:'/datenschutz'
    },
    {
        name:'Links',
        link:'/links'
    },
];