import React, {useCallback, useState} from "react";
import {Link} from "gatsby";
import styled, {createGlobalStyle} from "styled-components";
import {centered, primaryColor} from "../style/common";
import Image from "./Image";
import {Button} from "./Button";

const H1 = styled.h1`
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  margin-left: 10px;
  color: #444;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 1;
`;

const Content = styled.div`
  img,
  iframe {
    border-radius: 42px;
    border-bottom-left-radius: 21px;
  }
`;

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Montserrat;
  }
  *, p {
    margin: 0;
    padding: 0;
    font-size: 17px;
    font-weight: 300;
    line-height: 28px;
    color: #444;
    @media(min-width: 600px) {
      font-size: 19px;
      line-height: 32px;
    }
    strong {
      color: #000;
      font-weight: 500;
    }
  }
  body {
    border-top: 4px solid ${primaryColor};
  }
  p {
    margin: 24px 0;
    a {
      text-decoration: none;
      padding-bottom: 2px;
      border-bottom: 1px dotted #444;
    }
  }
  ul {
    list-style: circle outside;
    margin-left: 32px;
  }
  h1 {
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin: 0;
    @media(min-width: 600px) {
      font-size: 30px;
      font-weight: 700;
      line-height: 38px;
    }
  }
  h2 {
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
    margin: 60px 0 15px 0;
    @media(min-width: 600px) {
      font-size: 23px;
      line-height: 30px;
    }
  }
  h3 {
    font-weight: 700;
    margin: 50px 0 12px 0;
  }
  blockquote {
    margin: 24px 0;
    p {
      margin: 16px 0;
      border-left: 2px solid #444;
      padding: 4px 0 4px 16px;
      font-weight: 400;
      font-size: 20px;
      font-style: italic;
    }
  }
  cite {
    display: block;
    font-size: 14px;
    margin-left: 19px;
    margin-top: -16px;
  }
  table {
    border-collapse: collapse;
    margin: 16px 0;
    th {
      font-weight: 400;
      border-bottom: 1px solid #aaa;
    }
    th, td {
      text-align: left;
      padding: 8px 16px;
    }
    tr:nth-child(even) {
      background: #fafafa;
    }
  }
`;

const Menu = styled.ul`
  display: flex;
  flex: 1;
  list-style: none;
  flex-wrap: wrap;
  justify-content: flex-end;
  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
`;

const MenuItem = styled.li`
  padding: 10px;
`;

const MenuLink = styled(Link)`
  color: #444;
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  margin: 0 10px;
  padding-bottom: 5px;
  transition: 0.3s color ease;
  &:hover {
    color: #111;
    border-bottom: 1px dotted #444;
  }
  &.active {
    color: #111;
    border-bottom: 2px solid ${primaryColor};
    border-color: ${primaryColor};
  }
`;

const LogoLink = styled(Link)`
  ${centered}
  text-decoration: none;
`;

const Subtitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 200;
  @media (min-width: 600px) {
    font-size: 18px;
    line-height: 25px;
  }
  margin-bottom: 0;
`;

const Title = styled.h1`
  margin-bottom: 0;
`;

const Hero = styled.div`
  margin-top: 80px;
  margin-bottom: 80px;
  @media (min-width: 600px) {
    flex-direction: row;
    display: flex;
  }
`;

const HeroLeft = styled.div`
  position: relative;
  flex: 1;
`;

const HeroImage = styled(HeroLeft)`
  margin-top: 32px;
  @media (min-width: 600px) {
    padding-left: 16px;
    margin-top: 0;
  }
  img {
    border-radius: 72px;
    border-bottom-left-radius: 24px;
  }
`;

const SubMenu = styled.div`
  margin-top: 32px;
  display: inline-block;
  border-top: 2px solid #ffe7d2;
`;

const SubMenuLinkWrapper = styled.div`
  display: block;
`;

const SubMenuLink = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  display: inline;
  border-bottom: 1px dotted #fff;
  transition: 0.3s all ease;
  padding-bottom: 2px;
  &:hover {
    color: #111;
    border-color: #444;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const Footer = styled.footer`
  background: #f9f9f9;
  padding: 32px 0;
  margin-top: 80px;
  border-top: 1px solid #ddd;
`;

const Copyright = styled.div`
  margin-top: 32px;
`;

const FooterMenu = styled.div`
  display: inline-block;
`;

const FooterMenuLink = styled(Link)`
  text-decoration: none;
  display: inline-block;
  color: #000;
  line-height: 32px;
  font-size: 17px;
  border-bottom: 1px dotted transparent;
  &:hover {
    color: ${primaryColor};
  }
  margin-right: 32px;
  &:last-child {
    margin-right: 0;
  }
  transition: 0.3s color ease;
  padding-bottom: 4px;
  &:hover {
    color: #111;
    border-bottom: 1px dotted #444;
  }
`;

const ParentLink = styled(Link)`
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  position: absolute;
  bottom: 100%;
  color: #f9cda9;
`;

const CtaLinkWrapper = styled.div`
  ${centered}
  margin-top: 40px;
  justify-content: flex-start;
`;

const CtaLink = styled(Link)`
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  background-color: ${primaryColor};
  color: #fff;
  border-radius: 24px;
  min-height: 48px;
  ${centered}
  padding: 8px 24px;
  box-sizing: border-box;
`;

const Navigation = styled.nav`
  @media (max-width: 800px) {
    display: none;
    background: #fff;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
  }
  &.open {
    display: block;
  }
`;

const MobileNavigationTrigger = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
  cursor: pointer;
  role: button;
`;

const MobileNavigationHeader = styled.div`
  display: none;
  @media (max-width: 800px) {
    padding: 16px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top: 4px solid ${primaryColor};
  }
`;

const Layout = ({
  parent,
  title,
  subtitle,
  children,
  menu,
  subMenu,
  footerMenu,
  link,
  image,
  imageAlt
}) => {
  const [mobileNavigationOpen, setMobileNavigation] = useState(false);
  const openMobileNavigation = useCallback(() => setMobileNavigation(true), []);
  const closeMobileNavigation = useCallback(
    () => setMobileNavigation(false),
    []
  );

  return (
    <>
      <img src="/bg-form.svg" width="100%" style={{position: 'absolute', top: 4, zIndex: 0}} />
      <Wrapper>
        <GlobalStyle />
        <Header>
          <LogoLink to="/">
            <img src="/logo.png" width={50} />
            <H1>Yoga&nbsp;Orange</H1>
          </LogoLink>
          <Navigation className={mobileNavigationOpen ? "open" : ""}>
            <MobileNavigationHeader>
              <LogoLink className="mobile" to="/">
                <img src="/logo.png" width={50} />
                <H1>Yoga&nbsp;Orange</H1>
              </LogoLink>
              <MobileNavigationTrigger onClick={closeMobileNavigation}>
                <img src="/close.svg" width={20} />
              </MobileNavigationTrigger>
            </MobileNavigationHeader>
            <Menu>
              {menu.map(link => (
                <MenuItem key={link.name}>
                  <MenuLink
                    to={link.link}
                    activeClassName="active"
                    partiallyActive={true}
                  >
                    {link.name}
                  </MenuLink>
                </MenuItem>
              ))}
            </Menu>
          </Navigation>
          <MobileNavigationTrigger onClick={openMobileNavigation}>
            <img src="/menu.svg" width={25} />
          </MobileNavigationTrigger>
        </Header>
        <Hero>
          <HeroLeft>
            {parent && <ParentLink to={parent.link}>{parent.name}</ParentLink>}
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            {link && <Button link={link.url}>{link.title}</Button>}
            {subMenu && (
              <SubMenu>
                {subMenu.map((item, i) => (
                  <SubMenuLinkWrapper>
                    <SubMenuLink key={item.name} to={item.link}>
                      {item.name}
                    </SubMenuLink>
                  </SubMenuLinkWrapper>
                ))}
              </SubMenu>
            )}
          </HeroLeft>
          {image && (
            <HeroImage>
              <Image filename={image} alt={imageAlt} title={imageAlt} />
            </HeroImage>
          )}
        </Hero>
        <Content>{children}</Content>
      </Wrapper>
      <Footer>
        <Wrapper>
          <FooterMenu>
            {footerMenu.map((item, i) => (
              <FooterMenuLink key={item.name} to={item.link}>
                {item.name}
              </FooterMenuLink>
            ))}
          </FooterMenu>
          <Copyright>© {new Date().getFullYear()}, Yoga Orange</Copyright>
        </Wrapper>
      </Footer>
    </>
  );
};

export default Layout;
